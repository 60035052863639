import { useEffect, useRef, useCallback } from "react";
// services
import Glient from "../services/glient";
// types
import type { RxCallback } from "../types/roc-ws";
import type { HandlerId, PayloadTxKnown } from "../types/message";

const useSend = () => {
	const handlerIdRef = useRef(new Set<HandlerId>());

	const send = useCallback(<P extends PayloadTxKnown>(cmd: P, callback?: RxCallback<P>) => {
		const handlerId = Glient.send(cmd, (error, msg) => {
			callback?.(error, msg);
			handlerIdRef.current.delete(handlerId);
		});
		handlerIdRef.current.add(handlerId);
	}, []);

	useEffect(() => (
		() => {
			for (const handlerId of handlerIdRef.current) {
				Glient.abort(handlerId);
			}
			handlerIdRef.current.clear();
		}
	), []);

	return send;
};

export default useSend;
